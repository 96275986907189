/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

let apiToken = document.head.querySelector('meta[name="csrf-token"]');
window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + apiToken.content;


window.Vue = require('vue');
window.axios = require('axios');
window.moment = require('moment');

require('./autoFill');
window.Snackbar = require('node-snackbar');

window.axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
};

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// const app = new Vue({
//     el: '#app',
// });

/**
 * Mixin
 */
Vue.prototype.$appUrl = window.location.origin.replace(/(\/#|\/|#)$/, '');
window.Vue.mixin(require('./components/vueMixin.js'));
window.Vue.mixin(require('./components/formMixin.js'));

// Form components

Vue.component('sidebar', require('./components/layout/sidebars/Sidebar.vue').default);
Vue.component('sidebar-step', require('./components/layout/sidebars/SidebarStep2.vue').default);
Vue.component('auth-top-bar', require('./components/layout/AuthTopBar.vue').default);


Vue.component('register', require('./components/auth/Register.vue').default);
Vue.component('register-step-1', require('./components/auth/RegisterStep1.vue').default);
Vue.component('register-success', require('./components/auth/RegisterSuccess.vue').default);
// Vue.component('register-step-2', require('./components/auth/RegisterStep2.vue').default);
