var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("auth-top-bar", { attrs: { initialData: _vm.initialData } }),
      _vm._v(" "),
      _c("div", { staticClass: "auth-wrap" }, [
        _c(
          "div",
          { staticClass: "auth-sidebar" },
          [
            _c("sidebar-step", {
              attrs: { initialData: _vm.initialData, step: 2 },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "auth-content" }, [
          _c("main", { staticClass: "auth-main" }, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-center flex-column register-form-wrap",
              },
              [
                _c("br"),
                _vm._v(" "),
                _c(
                  "h1",
                  { staticClass: "page-heading pt-3 pt-xl-0 pb-4 mb-xl-2" },
                  [
                    _vm._v(
                      _vm._s(_vm.initialData.lang.app_tell_us_about_yourself)
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.formValidationErrors &&
                _vm.formValidationErrors["verification_token"]
                  ? _c("error-ele", {
                      attrs: {
                        errors: _vm.formValidationErrors["verification_token"],
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    staticClass: "register-form",
                    attrs: { autocomplete: "off" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.firstname,
                              expression: "form.firstname",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            placeholder:
                              _vm.initialData.lang.profile.fields.firstname,
                            autocomplete: "off",
                            autofill: "off",
                          },
                          domProps: { value: _vm.form.firstname },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.form,
                                "firstname",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.formValidationErrors &&
                        _vm.formValidationErrors["firstname"]
                          ? _c("error-ele", {
                              attrs: {
                                errors: _vm.formValidationErrors["firstname"],
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c(
                        "div",
                        { staticClass: "form-group col-md-6" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.lastname,
                                expression: "form.lastname",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              placeholder:
                                _vm.initialData.lang.profile.fields.lastname,
                              autocomplete: "off",
                              autofill: "off",
                            },
                            domProps: { value: _vm.form.lastname },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.form,
                                  "lastname",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.formValidationErrors &&
                          _vm.formValidationErrors["lastname"]
                            ? _c("error-ele", {
                                attrs: {
                                  errors: _vm.formValidationErrors["lastname"],
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group col-md-6" },
                        [
                          _c("v-datepicker", {
                            staticClass: "dob",
                            attrs: {
                              name: "dob",
                              placeholder:
                                _vm.initialData.lang.profile.fields.dob,
                              format: "YYYY-MM-DD",
                              locale: _vm.initialData.current_lang,
                              showDropDowns: true,
                              error:
                                _vm.formValidationErrors &&
                                _vm.formValidationErrors["dob"]
                                  ? _vm.formValidationErrors["dob"][0]
                                  : null,
                            },
                            model: {
                              value: _vm.form.dob,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "dob", $$v)
                              },
                              expression: "form.dob",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c(
                        "div",
                        { staticClass: "form-group col-md-6" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.address,
                                expression: "form.address",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              placeholder:
                                _vm.initialData.lang.profile.fields.address,
                              autocomplete: "off",
                              autofill: "off",
                            },
                            domProps: { value: _vm.form.address },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.form,
                                  "address",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.formValidationErrors &&
                          _vm.formValidationErrors["address"]
                            ? _c("error-ele", {
                                attrs: {
                                  errors: _vm.formValidationErrors["address"],
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group col-md-6" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.city,
                              expression: "form.city",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            readonly: _vm.form.city_locked,
                            placeholder:
                              _vm.initialData.lang.profile.fields.city,
                            autocomplete: "off",
                            autofill: "off",
                          },
                          domProps: { value: _vm.form.city },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.form, "city", $event.target.value)
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c(
                        "div",
                        { staticClass: "form-group col-md-6" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.province,
                                expression: "form.province",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              readonly: _vm.form.province_locked,
                              placeholder:
                                _vm.initialData.lang.profile.fields.province,
                              autocomplete: "off",
                              autofill: "off",
                            },
                            domProps: { value: _vm.form.province },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.form,
                                  "province",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.formValidationErrors &&
                          _vm.formValidationErrors["province"]
                            ? _c("error-ele", {
                                attrs: {
                                  errors: _vm.formValidationErrors["province"],
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group col-md-6" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.postalcode,
                                expression: "form.postalcode",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              placeholder:
                                _vm.initialData.lang.profile.fields.postalcode,
                              autocomplete: "off",
                              autofill: "off",
                            },
                            domProps: { value: _vm.form.postalcode },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.form,
                                  "postalcode",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.formValidationErrors &&
                          _vm.formValidationErrors["postalcode"]
                            ? _c("error-ele", {
                                attrs: {
                                  errors:
                                    _vm.formValidationErrors["postalcode"],
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.mobilephone,
                              expression: "form.mobilephone",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            required: "",
                            placeholder:
                              _vm.initialData.lang.profile.fields.mobilephone,
                            autocomplete: "off",
                          },
                          domProps: { value: _vm.form.mobilephone },
                          on: {
                            keypress: _vm.isNumberKey,
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.form,
                                "mobilephone",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.formValidationErrors &&
                        _vm.formValidationErrors["mobilephone"]
                          ? _c("error-ele", {
                              attrs: {
                                errors: _vm.formValidationErrors["mobilephone"],
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-center text-primary d-block mb-2 font-weight-bold",
                        attrs: {
                          "data-toggle": "collapse",
                          "data-target": "#collapseExample2",
                        },
                      },
                      [
                        _c("span", { staticClass: "cursor-pointer" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.initialData.lang.profile.fields.add_number
                              ) +
                              " "
                          ),
                          _c("span", { staticClass: "icon-addplus" }),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "collapse mb-3",
                        attrs: { id: "collapseExample2" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.homephone,
                                  expression: "form.homephone",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                required: "",
                                placeholder:
                                  _vm.initialData.lang.profile.fields.homephone,
                                autocomplete: "off",
                              },
                              domProps: { value: _vm.form.homephone },
                              on: {
                                keypress: _vm.isNumberKey,
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.form,
                                    "homephone",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.formValidationErrors &&
                            _vm.formValidationErrors["homephone"]
                              ? _c("error-ele", {
                                  attrs: {
                                    errors:
                                      _vm.formValidationErrors["homephone"],
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-center text-primary d-block mb-2 font-weight-bold",
                        attrs: {
                          "data-toggle": "collapse",
                          "data-target": "#collapseExample",
                        },
                      },
                      [
                        _c("span", { staticClass: "cursor-pointer" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.initialData.lang.app_add_authorize_user
                              ) +
                              " "
                          ),
                          _c("span", { staticClass: "icon-addplus" }),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "collapse mb-3",
                        attrs: { id: "collapseExample" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.auth_user_fname,
                                  expression: "form.auth_user_fname",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                autocomplete: "off",
                                placeholder:
                                  _vm.initialData.lang.profile.fields
                                    .auth_user_fname,
                                autofill: "off",
                              },
                              domProps: { value: _vm.form.auth_user_fname },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.form,
                                    "auth_user_fname",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.formValidationErrors &&
                            _vm.formValidationErrors["auth_user_fname"]
                              ? _c("error-ele", {
                                  attrs: {
                                    errors:
                                      _vm.formValidationErrors[
                                        "auth_user_fname"
                                      ],
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _c(
                            "div",
                            { staticClass: "form-group col-md-6" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.auth_user_lname,
                                    expression: "form.auth_user_lname",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  autocomplete: "off",
                                  placeholder:
                                    _vm.initialData.lang.profile.fields
                                      .auth_user_lname,
                                  autofill: "off",
                                },
                                domProps: { value: _vm.form.auth_user_lname },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.form,
                                      "auth_user_lname",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.formValidationErrors &&
                              _vm.formValidationErrors["auth_user_lname"]
                                ? _c("error-ele", {
                                    attrs: {
                                      errors:
                                        _vm.formValidationErrors[
                                          "auth_user_lname"
                                        ],
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group col-md-6" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.auth_user_phone,
                                    expression: "form.auth_user_phone",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "number",
                                  autocomplete: "off",
                                  placeholder:
                                    _vm.initialData.lang.profile.fields
                                      .auth_user_phone,
                                  autofill: "off",
                                },
                                domProps: { value: _vm.form.auth_user_phone },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.form,
                                      "auth_user_phone",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.formValidationErrors &&
                              _vm.formValidationErrors["auth_user_phone"]
                                ? _c("error-ele", {
                                    attrs: {
                                      errors:
                                        _vm.formValidationErrors[
                                          "auth_user_phone"
                                        ],
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.auth_user_email,
                                  expression: "form.auth_user_email",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                autocomplete: "off",
                                placeholder:
                                  _vm.initialData.lang.profile.fields
                                    .auth_user_email,
                                autofill: "off",
                              },
                              domProps: { value: _vm.form.auth_user_email },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.form,
                                    "auth_user_email",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.formValidationErrors &&
                            _vm.formValidationErrors["auth_user_email"]
                              ? _c("error-ele", {
                                  attrs: {
                                    errors:
                                      _vm.formValidationErrors[
                                        "auth_user_email"
                                      ],
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "footer",
        { staticClass: "d-flex flex-column justify-content-center" },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between align-items-center px-2 px-md-4 px-lg-5 footer-nav",
            },
            [
              _c("div"),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "button-loader",
                    {
                      attrs: {
                        disabled: _vm.submitLoading,
                        classes: "btn btn-primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.saveForm(
                            _vm.initialData.lang.app_addPetNow
                          )
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.initialData.lang.app_registerUser) +
                          "\n                    "
                      ),
                      _c("span", { staticClass: "icon-arrow-right" }),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }